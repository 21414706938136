import SessionCache from '@plugins/cache';

const SET_UNREAD_MESSAGES = 'SET_UNREAD_MESSAGES';
const SET_IS_ACCEPTANCES_SHOWN = 'SET_IS_ACCEPTANCES_SHOWN';
const SET_IS_COMMUNICATION_SHOWN = 'SET_IS_COMMUNICATION_SHOWN';
const SET_BLOCKING_CAMPAIGNS = 'SET_BLOCKING_CAMPAIGNS';
const SET_CURRENT_CAMPAIGN = 'SET_CURRENT_CAMPAIGN';
const cache = new SessionCache('communications');

const hashCode = (str) =>
	str
		.split('')
		.reduce((red, value) => ((red << 5) - red + value.charCodeAt(0)) | 0, 0);

/* istanbul ignore next */
const compareVersions = (version1, version2) => {
	const validPattern = /^(\d+)\.(\d+)\.(\d+)$/;

	if (!validPattern.test(version1) || !validPattern.test(version2)) {
		return { isValid: false };
	}

	const [, major1, minor1, patch1] = version1.match(validPattern);
	const [, major2, minor2, patch2] = version2.match(validPattern);

	const isGreaterThan = () => {
		if (parseInt(major1, 10) > parseInt(major2, 10)) {
			return true;
		}
		if (parseInt(major1, 10) < parseInt(major2, 10)) {
			return false;
		}

		if (parseInt(minor1, 10) > parseInt(minor2, 10)) {
			return true;
		}
		if (parseInt(minor1, 10) < parseInt(minor2, 10)) {
			return false;
		}

		return parseInt(patch1, 10) > parseInt(patch2, 10);
	};

	const isLowerThan = () => {
		if (parseInt(major1, 10) < parseInt(major2, 10)) {
			return true;
		}
		if (parseInt(major1, 10) > parseInt(major2, 10)) {
			return false;
		}

		if (parseInt(minor1, 10) < parseInt(minor2, 10)) {
			return true;
		}
		if (parseInt(minor1, 10) > parseInt(minor2, 10)) {
			return false;
		}

		return parseInt(patch1, 10) < parseInt(patch2, 10);
	};

	const isEqualTo = () => {
		return (
			parseInt(major1, 10) === parseInt(major2, 10) &&
			parseInt(minor1, 10) === parseInt(minor2, 10) &&
			parseInt(patch1, 10) === parseInt(patch2, 10)
		);
	};

	return {
		isValid: true,
		isGreaterThan,
		isLowerThan,
		isEqualTo,
	};
};

export default {
	namespaced: true,

	state() {
		return {
			unreadMessages: 0,
			isAcceptancesShown: false,
			isCommunicationShown: false,
			hasBlockingCampaigns: null,
			currentCampaign: {},
		};
	},

	mutations: {
		[SET_UNREAD_MESSAGES](state, unreadMessages) {
			state.unreadMessages = unreadMessages;
		},

		[SET_IS_ACCEPTANCES_SHOWN](state, isAcceptancesShown) {
			state.isAcceptancesShown = isAcceptancesShown;
		},

		[SET_IS_COMMUNICATION_SHOWN](state, isCommunicationShown) {
			state.isCommunicationShown = isCommunicationShown;
		},

		[SET_BLOCKING_CAMPAIGNS](state, hasBlockingCampaigns) {
			state.hasBlockingCampaigns = hasBlockingCampaigns;
		},

		[SET_CURRENT_CAMPAIGN](state, currentCampaign) {
			state.currentCampaign = currentCampaign;
		},
	},

	actions: {
		setCurrentCampaign({ commit }, value) {
			commit(SET_CURRENT_CAMPAIGN, value);
		},

		acceptanceCampaignDisplayed({ commit }, value) {
			commit(SET_IS_ACCEPTANCES_SHOWN, value);
		},

		postLoginDisplayed({ commit }, value) {
			commit(SET_IS_COMMUNICATION_SHOWN, value);
		},

		postFeedback({ dispatch }, payload) {
			const url = '/communications/feedback';
			const method = 'POST';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
					payload,
				},
				{ root: true }
			);
		},

		getUnreadMessages({ commit, dispatch, state, rootState }) {
			if (rootState?.app?.isEmbedded) {
				return Promise.resolve({ data: { unreadMessages: 0 } });
			}

			const url = '/communications/unread-messages';
			const method = 'GET';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
				},
				{ root: true }
			)
				.then(({ data: { unreadMessages } }) => {
					/* istanbul ignore else */
					if (unreadMessages !== state.unreadMessages) {
						commit(SET_UNREAD_MESSAGES, unreadMessages);
						cache.clear();
					}

					return unreadMessages;
				})
				.catch(() => {
					commit(SET_UNREAD_MESSAGES, 0);
					return 0;
				});
		},

		getCommunicationItems(
			{ dispatch, rootState },
			{ type, paginationKey, dateFrom, dateTo, searchType }
		) {
			if (rootState?.app?.isEmbedded) {
				return Promise.resolve({ data: [] });
			}

			const key = hashCode('message'.concat(dateFrom, dateTo, searchType));
			const url = `/communications/${type}`;
			const method = 'GET';
			const cacheKey = `${type}/${key}`;
			const queryParams = {};

			/* istanbul ignore next */
			if (cache.has(cacheKey) && !paginationKey) {
				return cache.get(cacheKey);
			}

			if (paginationKey) {
				Object.assign(queryParams, { paginationKey });
			}

			if (dateFrom) {
				Object.assign(queryParams, { dateFrom });
			}

			if (dateTo) {
				Object.assign(queryParams, { dateTo });
			}

			if (searchType) {
				Object.assign(queryParams, { type: searchType });
			}

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
					queryParams,
				},
				{ root: true }
			).then(({ data }) => {
				const response = {
					paging: data.paging,
					data: data.data.map((item) => {
						// We create cache the each item
						cache.set(`${type}/${item?.id}`, item);
						return item;
					}),
				};

				/* istanbul ignore next */
				if (cache.has(cacheKey)) {
					const result = cache.get(cacheKey);
					Object.assign(response.data, result.data.concat(response.data));
				}

				cache.set(cacheKey, response);

				return response;
			});
		},

		getDocuments(
			{ dispatch },
			{ paginationKey, dateFrom, dateTo, searchType } = {}
		) {
			return dispatch('getCommunicationItems', {
				type: 'documents',
				paginationKey,
				dateFrom,
				dateTo,
				searchType,
			});
		},

		getMessages({ dispatch }, { paginationKey, dateFrom, dateTo } = {}) {
			return dispatch('getCommunicationItems', {
				type: 'messages',
				paginationKey,
				dateFrom,
				dateTo,
			});
		},

		async getDocument({ dispatch }, id) {
			let item = cache.get(`documents/${id}`);

			if (!item) {
				const { data } = await dispatch('getDocuments');

				item = data.find(({ id: itemId }) => itemId === id);
			}

			return item;
		},

		getDocumentAttachment({ dispatch }, { id, iban }) {
			const url = `/communications/documents/${id}`;
			const method = 'GET';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
					queryParams: { iban },
				},
				{ root: true }
			).then(({ data }) => data);
		},

		getMessage({ dispatch }, id) {
			const url = `/communications/messages/${id}`;
			const method = 'GET';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
				},
				{ root: true }
			).then(({ data }) => data);
		},

		markCommunicationItemAsRead({ dispatch }, { itemType, type, id }) {
			const url = `/communications/${itemType}/${id}`;
			const method = 'PATCH';
			const cacheData = cache.get(`${itemType}/${id}`);

			cache.set(`${itemType}/${id}`, {
				...cacheData,
				reviewDate: new Date().toISOString(),
			});

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
					payload: { type },
				},
				{ root: true }
			)
				.then(() => cache.clear())
				.catch(() => {});
		},

		markDocumentAsRead({ dispatch }, { id, type }) {
			return dispatch('markCommunicationItemAsRead', {
				itemType: 'documents',
				id,
				type,
			});
		},

		markMessageAsRead({ dispatch }, { id, type }) {
			return dispatch('markCommunicationItemAsRead', {
				itemType: 'messages',
				id,
				type,
			});
		},

		getAnnouncements(
			{ dispatch, commit, state, rootState },
			{ spaceName, refresh }
		) {
			if (rootState?.app?.isEmbedded) {
				return Promise.resolve({ data: [] });
			}
			if (spaceName === 'postlogin' && state.isCommunicationShown) {
				return Promise.resolve({ data: [] });
			}

			const spaces = { prelogin: 'prelogin' };
			const spaceIds = {
				postlogin: 2,
				banner: 3,
			};
			const expURL = spaces[spaceName] || 'communications';
			const url = `/${expURL}/communications`;
			const queryParams =
				spaceName === 'prelogin'
					? {
							language: rootState.session.lang,
							company: rootState.app.companyId,
					  }
					: { spaceId: spaceIds[spaceName] };
			const key = `communications/${spaceName}`;
			const MAX_SLIDES = 3;

			if (refresh) {
				SessionCache.clear(key);
			}

			/* istanbul ignore next */
			if (cache.has(key)) {
				return cache.get(key);
			}

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method: 'GET',
						},
					},
					queryParams,
				},
				{ root: true }
			)
				.then(({ data: { data } }) => {
					const filteredData = data.filter((slide) => {
						if (slide.destinationChannel) {
							const isWebBlocking = slide.destinationChannel === 'WEB';
							const userOnMobile =
								rootState.app.isHybrid || rootState.app.isHybridSky;

							// If it is blocking for web users and user is on mobile or it blocks mobiles and user is on web, it will not be showed.
							/* istanbul ignore else */
							if (
								(isWebBlocking && userOnMobile) ||
								(!isWebBlocking && !userOnMobile)
							) {
								return false;
							}

							// If it is blocking for mobile and user is on mobile we have to check a few more fields such as OS and appVersion
							if (!isWebBlocking && userOnMobile) {
								const deviceData = rootState.device;

								const isIOSBlockingAndUserOnAndroid =
									deviceData.mobileOs === 'IOS' &&
									slide.destinationChannel === 'ANDROID';
								const isAndroidBlockingAndUserOnIOS =
									deviceData.mobileOs === 'ANDROID' &&
									slide.destinationChannel === 'IOS';

								// If it affects the other OS, slide should not be showed.
								if (
									isAndroidBlockingAndUserOnIOS ||
									isIOSBlockingAndUserOnAndroid
								) {
									return false;
								}

								// If it affects different versions, slide should not be showed.
								const versionComparisonResult1 = compareVersions(
									slide.toVersion,
									deviceData.appVersion
								);
								const versionComparisonResult2 = compareVersions(
									slide.fromVersion,
									deviceData.appVersion
								);

								/* istanbul ignore next */
								if (
									!versionComparisonResult1.isValid &&
									!versionComparisonResult2.isValid
								) {
									return true;
								}

								/* istanbul ignore next */
								if (
									!versionComparisonResult1.isValid &&
									versionComparisonResult2.isValid
								) {
									return (
										versionComparisonResult2.isEqualTo() ||
										versionComparisonResult2.isLowerThan()
									);
								}

								/* istanbul ignore next */
								if (
									versionComparisonResult1.isValid &&
									!versionComparisonResult2.isValid
								) {
									return (
										versionComparisonResult1.isEqualTo() ||
										versionComparisonResult1.isGreaterThan()
									);
								}

								return (
									(versionComparisonResult1.isEqualTo() ||
										versionComparisonResult1.isGreaterThan()) &&
									(versionComparisonResult2.isEqualTo() ||
										versionComparisonResult2.isLowerThan())
								);
							}
						}

						// Keep the slide if it doesn't meet any of the conditions above
						return true;
					});

					const hasBlockingSlide = filteredData.some(
						(slide) => slide.blocking
					);

					const orderedData = filteredData.sort((a, b) => {
						const blockingComparison = b.blocking - a.blocking;
						if (blockingComparison !== 0) {
							return blockingComparison;
						}

						const aIsAcceptance = a.ctaAction === 'acceptance' ? 1 : 0;
						const bIsAcceptance = b.ctaAction === 'acceptance' ? 1 : 0;

						if (bIsAcceptance !== aIsAcceptance) {
							return bIsAcceptance - aIsAcceptance;
						}
						return a.type?.priority - b.type?.priority;
					});

					const slicedData = orderedData.slice(0, MAX_SLIDES);

					cache.set(key, slicedData);

					commit(SET_BLOCKING_CAMPAIGNS, hasBlockingSlide);
					return slicedData;
				})
				.catch(() => {
					/* istanbul ignore next */
					commit(SET_BLOCKING_CAMPAIGNS, false);
				});
		},

		changeAnnouncementData({ dispatch }, { id, data }) {
			const url = `/communications/communications/${id}`;
			const method = 'PATCH';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
					payload: { ...data },
				},
				{ root: true }
			);
		},

		setAnnauncementCtaPressed({ dispatch }, { id, ctaPressed }) {
			return dispatch('changeAnnouncementData', {
				id,
				data: { ctaPressed },
			});
		},

		setAnnouncementFeedback({ dispatch }, { id, feedback }) {
			return dispatch('changeAnnouncementData', {
				id,
				data: { feedback },
			});
		},

		setAnnouncementImpression({ dispatch }, id) {
			return dispatch('changeAnnouncementData', {
				id,
				data: { impressions: 1 },
			});
		},

		getDocumentsTypes({ dispatch }) {
			const url = '/communications/documents/types';
			const method = 'GET';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
				},
				{ root: true }
			).then(({ data: { data } }) => data);
		},

		hideBanner() {
			cache.set('communications/banner', null);
		},
	},
};
